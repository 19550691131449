import React from "react";
import { useStaticQuery, graphql, Link as GLink } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import { Box, Text, Card, Flex, Button } from "theme-ui";
import { Layout, Stack, Main } from "@layout";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import Section from "@components/Section";
import Seo from "@widgets/Seo";

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`,
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4,
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    " > div + div": {
      ml: [0, 0, 5],
    },
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`],
  },
};

const About = (props) => {
  const data = useStaticQuery(aboutQuery);
  const image = getImage(data.avatar);

  return (
    <Layout {...props}>
      <Seo title="About" />
      <Divider />
      <Stack>
        <Main>
          <PageTitle header="About" />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button as={GLink} to="/contact" sx={styles.button}>
              Contact Me
            </Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title="My Story">
                <Text variant="p">
                  I was born in 1994 to a Greek 🇬🇷 father and a Colombian 🇨🇴
                  mother. I grew up in Athens until I was 18 and then lived in
                  Amsterdam 🇳🇱 for 10 years. Currently, I live in Bogota.
                </Text>
                <Text variant="p">
                  In 2015, I began learning web development through books 📚,
                  YouTube, and FreeCodeCamp projects. I landed my first tech job
                  as a Junior Frontend Developer in April 2018, where I worked
                  with multiple technologies including React, Angular, JS, and
                  WordPress.
                </Text>
                <Text variant="p">
                  In November 2018, I secured a Software Developer position at
                  Semmtech in Amsterdam. During my four years there, I worked
                  with React, Typescript, Material-UI, Java, Spring Boot,
                  MongoDB, and RDF.
                </Text>
                <Text variant="p">
                  In May 2022, I joined the Greek navy ⚓ for my mandatory
                  military service. During my time there, I developed a module
                  that processed geodata 🌍 using Spring Boot, PostgreSQL, and
                  PostGIS. My army service ended on February 9th, 2023.
                </Text>
                <Text variant="p">
                  Currently, I am embarking on a new journey as a freelancer and
                  blogger.
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title="Why this blog">
                <Card variant="paper">
                  As a self-taught web developer with five years of working
                  experience, my passion for web development has driven me to
                  create this blog. My goal is to help other members of the
                  community in any way possible.
                </Card>
              </Section>
              {/* <Divider />
              <Section title="Expertise">
                <Card variant="paper">
                   I have the most experience at the frontend side of things so if I had to choose between the two,
                   I would go wit
                </Card>
              </Section>
              <Divider />
              <Section title="Creative Designing">
                <Card variant="paper">
                  A core responsibility of the designer's job is to present
                  information in a way that is both accessible and memorable.
                </Card>
              </Section> */}
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  );
};

export default About;

const aboutQuery = graphql`
  query AboutCustomQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1076
          height: 605
          transformOptions: { cropFocus: CENTER }
        )
      }
    }
  }
`;
